import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
  	<div className="404-page" style={{padding: "30px"}}>
	    <SEO title="404: Not found" />
	    <h1>PAGE NOT FOUND</h1>
	    <p>You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
